import './App.css';
import TopBar from './components/Topbar/Topbar';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { Outlet } from 'react-router-dom';
import React from 'react';

class App extends React.Component {
  componentDidMount(): void {
    const scrollTop = document.querySelector('.scroll-top');
    if (scrollTop) {
      const togglescrollTop = function () {
        window.scrollY > 100
          ? scrollTop.classList.add('active')
          : scrollTop.classList.remove('active');
      };
      window.addEventListener('load', togglescrollTop);
      document.addEventListener('scroll', togglescrollTop);
    }
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  render() {
    return (
      <>
        <TopBar />
        <Header />
        <Outlet />
        <Footer />

        <button
          onClick={this.scrollToTop}
          className="scroll-top d-flex align-items-center justify-content-center"
        >
          <i className="bi bi-arrow-up-short"></i>
        </button>
        {document.readyState === 'loading' && <div id="preloader"></div>}
      </>
    );
  }
}

export default App;
