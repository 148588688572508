import React, { FC } from 'react';
import './Services.css';

interface ServicesProps {}

const Services: FC<ServicesProps> = () => (
  <section id="services" className="the-main-service text-center">
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <div className="section-header">
            <h2>
              Was <span>bieten</span> wir?
            </h2>
            <p>Wir bieten individuell auf Sie zugeschnittene Lösungen.</p>
          </div>
        </div>
      </div>
      <div className="row our-offer-items less-carousel">
        <div className="col-md-4 col-sm-6 equal-height">
          <div className="item">
            <i className="fa fa-desktop"></i>
            <h4>Frontend-Entwicklung</h4>
            <p>
              Entwurf, Design und Implementierung webbasierter, responsiver User
              Interfaces
            </p>
          </div>
        </div>

        <div className="col-md-4 col-sm-6 equal-height">
          <div className="item">
            <i className="fa fa-server"></i>
            <h4>Backend-Entwicklung</h4>
            <p>Vom Monolith bis zur komplexen Micro-Service-Architektur</p>
          </div>
        </div>

        <div className="col-md-4 col-sm-6 equal-height">
          <div className="item">
            <i className="fa fa-mobile"></i>
            <h4>App-Entwicklung</h4>
            <p>Entwicklung für Android oder iOS</p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Services;
