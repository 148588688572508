import React, { FC } from 'react';
import './Topbar.css';

interface TopbarProps {}

const Topbar: FC<TopbarProps> = () => (
  <section id="topbar" className="topbar d-flex align-items-center">
    <div className="container d-flex justify-content-end">
      <div className="social-links d-none d-md-flex align-items-center">
        <div className="contact-info d-flex align-items-center">
          <i className="bi bi-envelope d-flex align-items-center">
            <a href="mailto:kontakt@otter-it-consulting.com">
              kontakt@otter-it-consulting.com
            </a>
          </i>
          <i className="bi bi-phone d-flex align-items-center ms-4">
            <span>
              <a href="tel:+4915901426337">+49 159 01426337</a>
            </span>
          </i>
        </div>
      </div>
    </div>
  </section>
);

export default Topbar;
