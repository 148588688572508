import React, { BaseSyntheticEvent } from 'react';
import './Contact.css';
import { Link } from 'react-router-dom';

interface ContactProps {
  showHeader?: boolean;
}

class Contact extends React.Component<ContactProps> {
  async onSubmit($event: BaseSyntheticEvent<SubmitEvent>) {
    $event.preventDefault();

    try {
      const response = await fetch('./static/contact.php', {
        method: 'POST',
        body: new FormData($event.target),
      });

      if (response.status !== 200) {
        throw new Error('Server error');
      }

      document.querySelector('#msgSubmit')?.classList.add('show');
      document.querySelector('#msgSubmit')?.classList.remove('hidden');

      document.querySelector('#msgError')?.classList.remove('show');
      document.querySelector('#msgError')?.classList.add('hidden');
    } catch {
      document.querySelector('#msgSubmit')?.classList.remove('show');
      document.querySelector('#msgSubmit')?.classList.add('hidden');

      document.querySelector('#msgError')?.classList.add('show');
      document.querySelector('#msgError')?.classList.remove('hidden');
    }
  }

  render() {
    return (
      <div id="contact" className="contact-section section">
        {this.props.showHeader && (
          <div className="section-header">
            <h2>Kontaktieren Sie uns</h2>
            <p>Ihre Experten für Softwarelösungen</p>
          </div>
        )}
        <div className="container">
          <div className="row">
            <div className="col-lg-8 col-md-12" data-aos="fade-left">
              <div className="contact-form-box contact-form contact-form-3">
                <div className="form-container-box">
                  <form
                    className="contact-form form"
                    onSubmit={this.onSubmit.bind(this)}
                  >
                    <div className="controls">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              placeholder="Name*"
                              required={true}
                              data-error="Bitte geben Sie Ihren Namen ein."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-input-box">
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              placeholder="Email*"
                              required={true}
                              data-error="Bitte geben Sie eine gütige E-Mail ein."
                            />
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group form-input-box">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Betreff"
                              required={true}
                            />
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box">
                            <textarea
                              className="form-control"
                              id="message"
                              name="message"
                              rows={7}
                              placeholder="Ihre Nachricht*"
                              required={true}
                              data-error="Bitte geben Sie Ihre Nachricht ein."
                            ></textarea>
                            <div className="help-block with-errors"></div>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group form-input-box d-flex align-items-center gap-3">
                            <input
                              type="checkbox"
                              id="acceptDataProtectionPolicy"
                              required={true}
                            />{' '}
                            <div>
                              Wir verwenden Ihre Angaben zur Beantwortung Ihrer
                              Anfrage. Weitere Informationen finden Sie in
                              unseren{' '}
                              <Link
                                to={'/policy'}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                Datenschutzerklärung
                              </Link>
                              .
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <button type="submit" data-text="Send Message">
                            Nachricht abschicken
                          </button>
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-box alert-success alert-dismissable alert-dismissable hidden fade"
                            id="msgSubmit"
                            role="alert"
                          >
                            Danke! Ihre Nachricht wurde versandt.
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="alert"
                              aria-label="Close"
                            ></button>
                          </div>
                        </div>
                        <div className="messages">
                          <div
                            className="alert alert alert-box alert-danger alert-dismissable alert-dismissable hidden fade"
                            id="msgError"
                            role="alert"
                          >
                            <div>
                              Leider gab es ein technisches Problem. <br />
                              Bitte versuchen Sie es später erneut oder schicken
                              uns einfach eine E-Mail&nbsp;
                              <a href="mailto:kontakt@otter-it-consulting.com">
                                kontakt@otter-it-consulting.com
                              </a>
                              .
                            </div>
                            <button
                              type="button"
                              className="btn-close"
                              data-bs-dismiss="alert"
                              aria-label="Close"
                            ></button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-12" data-aos="fade-right">
              <div className="contact-information-box-3">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>Mobil:</h6>
                        <p>+49 159 01426337</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="single-contact-info-box">
                      <div className="contact-info">
                        <h6>E-Mail:</h6>
                        <p>kontakt@otter-it-consulting.com</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Contact;
