import './Footer.css';
import { FC } from 'react';
import { Link } from 'react-router-dom';

interface FooterProps {}

const Footer: FC<FooterProps> = () => (
  <footer id="footer" className="footer-section">
    <div className="container">
      <div className="footer-content pt-5 pb-5">
        <div className="row">
          <div className="col-xl-6 col-lg-6 mb-50">
            <div className="footer-widget">
              <div className="footer-logo">
                <a href="index" className="logo d-flex align-items-center">
                  <h1>Otter IT Consulting</h1>
                </a>
              </div>
              <div className="footer-text">
                <p>Ihre IT-Softwarespezialisten in ihrer Region.</p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
            <div className="footer-widget">
              <div className="footer-widget-heading">
                <h3>Nützliche Links</h3>
              </div>
              <ul>
                <li>
                  <Link to={'/'}>Startseite</Link>
                </li>
                <li>
                  <Link to={'team'}>Unser Team</Link>
                </li>
                <li>
                  <Link to={'career'}>Karriere</Link>
                </li>
                <li>
                  <Link to={'contact'}>Kontakt</Link>
                </li>
                <li>
                  <Link to={'impressum'}>Impressum</Link>
                </li>
                <li>
                  <Link to={'policy'}>Datenschutz</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="copyright-area">
      <div className="container">
        <div className="row">
          <div className="col-xl-6 col-lg-6 text-left text-lg-left">
            <div className="copyright-text">
              <p>Otter IT Consulting UG (haftungsbeschränkt) © 2023</p>
            </div>
          </div>
          <div className="col-xl-6 col-lg-6 d-none d-lg-block">
            <div className="footer-menu text-right">
              <ul>
                <li>
                  <Link to={'/'}>Startseite</Link>
                </li>
                <li>
                  <Link to={'impressum'}>Impressum</Link>
                </li>
                <li>
                  <Link to={'policy'}>Datenschutz</Link>
                </li>
                <li>
                  <Link to={'contact'}>Kontakt</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;
