import React, { FC } from 'react';
import './Breadcrumbs.css';
import { Link } from 'react-router-dom';

interface BreadcrumbsProps {
  title: string;
  section: string;
  desc?: string;
}

const Breadcrumbs: FC<BreadcrumbsProps> = (props: BreadcrumbsProps) => (
  <div className="breadcrumbs">
    <div
      className="page-header d-flex align-items-center"
      style={{ backgroundImage: "url('')" }}
    >
      <div className="container position-relative">
        <div className="row d-flex justify-content-center">
          <div className="col-lg-6 text-center">
            <h2>{props.title}</h2>
            <p>{props.desc}</p>
          </div>
        </div>
      </div>
    </div>
    <nav>
      <div className="container">
        <ol>
          <li>
            <Link to={'/'}>Startseite</Link>
          </li>
          <li>{props.section}</li>
        </ol>
      </div>
    </nav>
  </div>
);

export default Breadcrumbs;
