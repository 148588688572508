import './Header.css';
import React from 'react';
import Navbar from '../Navbar/Navbar';
import Logo from '../../assets/logo.svg';

class Header extends React.Component {
  componentDidMount(): void {
    this.enableStickyHeader();
  }

  render(): React.ReactNode {
    return (
      <header id="header" className="header d-flex align-items-center">
        <div className="container container-xl d-flex align-items-center justify-content-between">
          <a href="/" className="logo d-flex align-items-center">
            {/* <img src={require('../../assets/logo.svg')}/> */}
            {/* <svg path={Logo}></svg> */}
            {/* <object data={Logo}/> */} 
            {/* <object data={require('../../assets/logo.svg')} width="300" height="300"> </object> */}
          </a>
          <Navbar />
        </div>
      </header>
    );
  }

  private enableStickyHeader() {
    const selectHeader: HTMLElement = document.querySelector('#header')!;
    if (selectHeader) {
      let headerOffset = selectHeader.offsetTop;
      let nextElement = selectHeader.nextElementSibling;

      const headerFixed = () => {
        if (headerOffset - window.scrollY <= 0) {
          selectHeader.classList.add('sticked');
          if (nextElement) nextElement.classList.add('sticked-header-offset');
        } else {
          selectHeader.classList.remove('sticked');
          if (nextElement)
            nextElement.classList.remove('sticked-header-offset');
        }
      };
      window.addEventListener('load', headerFixed);
      document.addEventListener('scroll', headerFixed);
    }

    // TODO Check what the following lines are doing
    // if (selectHeader) {
    //   const togglescrollTop = function () {
    //     window.scrollY > 100 ? selectHeader.classList.add('stikcy-menu') : selectHeader.classList.remove('stikcy-menu');
    //   };
    //   window.addEventListener('load', togglescrollTop);
    //   document.addEventListener('scroll', togglescrollTop);
    //   // TODO Check typing
    //   selectHeader.addEventListener(
    //     'click',
    //     window.scrollTo({
    //       top: 0,
    //       behavior: 'smooth',
    //     }) as any
    //   );
    // }
  }
}

export default Header;
