import React from 'react';
import './Navbar.css';
import { Link } from 'react-router-dom';

class Navbar extends React.Component {
  once = false;

  // TODO Find a better solution than componentDidMount
  componentDidMount(): void {
    if (this.once) {
      return;
    }

    this.once = true;

    /**
     * Navbar links active state on scroll
     */
    const navbarlinks = document.querySelectorAll<HTMLElement>('#navbar a');

    function navbarlinksActive() {
      navbarlinks.forEach((navbarlink: HTMLElement) => {
        if (!(navbarlink as HTMLAnchorElement).hash) {
          return;
        }

        let section: HTMLElement = document.querySelector(
          (navbarlink as HTMLAnchorElement).hash
        )!;
        if (!section) {
          return;
        }

        let position = window.scrollY + 200;

        if (
          position >= section.offsetTop &&
          position <= section.offsetTop + section.offsetHeight
        ) {
          navbarlink.classList.add('active');
        } else {
          navbarlink.classList.remove('active');
        }
      });
    }
    window.addEventListener('load', navbarlinksActive);
    document.addEventListener('scroll', navbarlinksActive);

    /**
     * Mobile nav toggle
     */
    const mobileNavShow = document.querySelector('.mobile-nav-show');
    const mobileNavHide = document.querySelector('.mobile-nav-hide');

    document.querySelectorAll('.mobile-nav-toggle').forEach((el) => {
      el.addEventListener('click', function (event) {
        event.preventDefault();
        mobileNavToogle();
      });
    });

    function mobileNavToogle() {
      console.log(document.body.classList.toggle('mobile-nav-active'));
      mobileNavShow?.classList.toggle('d-none');
      mobileNavHide?.classList.toggle('d-none');
    }

    /**
     * Hide mobile nav on same-page/hash links
     */
    navbarlinks.forEach((navbarlink) => {
      navbarlink.addEventListener('click', () => {
        if (document.querySelector('.mobile-nav-active')) {
          mobileNavToogle();
        }
      });
    });

    /**
     * Toggle mobile nav dropdowns ???
     */
    const navDropdowns = document.querySelectorAll('.navbar .dropdown > a');
    navDropdowns.forEach((el) => {
      el.addEventListener('click', function (this: HTMLElement, event) {
        if (document.querySelector('.mobile-nav-active')) {
          console.log('hello there');
          event.preventDefault();
          this.classList.toggle('active');
          this.nextElementSibling?.classList.toggle('dropdown-active');

          let dropDownIndicator = this.querySelector('.dropdown-indicator');
          dropDownIndicator?.classList.toggle('bi-chevron-up');
          dropDownIndicator?.classList.toggle('bi-chevron-down');
        }
      });
    });
  }

  render(): React.ReactNode {
    return (
      <>
        <nav id="navbar" className="navbar">
          <ul>
            <li>
              <Link to={`/`}>Startseite</Link>
            </li>
            <li>
              <Link to={`team`}>Team</Link>
            </li>
            <li>
              <Link to={`career`}>Karriere</Link>
            </li>
            <li>
              <Link to={`contact`}>Kontakt</Link>
            </li>
          </ul>
        </nav>
        <i className="mobile-nav-toggle mobile-nav-show bi bi-list"></i>
        <i className="mobile-nav-toggle mobile-nav-hide d-none bi bi-x"></i>
      </>
    );
  }
}

export default Navbar;
