import React, { FC } from 'react';
import './GettingStarted.css';

interface GettingStartedProps {}

const GettingStarted: FC<GettingStartedProps> = () => (
  <section id="call-to-action">
    <div className="container text-center call-to-action" data-aos="zoom-out">
      <h3>Sie wollen etwas Großartiges starten?</h3>
      <p>Dann zögern Sie nicht uns umgehend zu kontaktieren!</p>
      <a className="cta-btn" href="mailto:kontakt@otter-it-consulting.com">
        Kontaktieren Sie uns
      </a>
    </div>
  </section>
);

export default GettingStarted;
