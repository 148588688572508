import { FC } from 'react';
import './Team.css';
import AlexanderOtt from '../../assets/images/team/Alexander_Ott.jpg';
import Roy from '../../assets/images/team/Roy.jpg';

interface TeamProps {
  showTitle?: boolean;
}

const Team: FC<TeamProps> = (props: TeamProps) => (
  <section id="team" className="team">
    <div className="container" data-aos="fade-up">
      {props.showTitle && (
        <div className="section-header">
          <h2>Unser Team</h2>
        </div>
      )}
      <div className="row gy-4">
        <div
          className="col-xl-3 col-md-6 d-flex"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="member">
            <img
              src={AlexanderOtt}
              className="img-fluid"
              alt=""
              loading="lazy"
            />
            <div className="social">
              <a href="https://www.linkedin.com/in/alexander-ott-272b3b70/">
                <i className="bi bi-linkedin"></i>
              </a>
            </div>
            <h4>Alexander Ott</h4>
            <span>Geschäftsführer / IT-Consultant</span>
          </div>
        </div>

        <div
          className="col-xl-3 col-md-6 d-flex"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="member">
            <img src={Roy} className="img-fluid" alt="" loading="lazy" />
            <div className="social"></div>
            <h4>Roy</h4>
            <span>Feel Good Manager</span>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default Team;
