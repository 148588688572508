import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import Team from './components/Team/Team';
import Career from './components/Career/Career';
import Contact from './components/Contact/Contact';
import Impressum from './components/Impressum/Impressum';
import Policy from './components/Policy/Policy';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.min.css';
import 'glightbox/dist/css/glightbox.min.css';
import './assets/styles/font-awesome.min.css';

import 'bootstrap/dist/js/bootstrap.min';

import * as AOS from 'aos';
import 'aos/dist/aos.css';

import Landing from './components/Landing/Landing';
import Breadcrumbs from './components/Breadcrumbs/Breadcrumbs';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: '',
        element: <Landing />,
      },
      {
        path: 'team',
        element: (
          <>
            <ScrollToTop />
            <Breadcrumbs
              title="Unser Team"
              section="Team"
              desc="Unsere hoch motivierten und qualifizierten Mitarbeiter stehen Ihnen mit Rat und Tat zur Seite."
            />
            <div className="single-page">
              <Team />
            </div>
          </>
        ),
      },
      {
        path: 'career',
        element: (
          <>
            <ScrollToTop />
            <Breadcrumbs title="Karriere" section="Karriere" />
            <section className="single-page">
              <Career />
            </section>
          </>
        ),
      },
      {
        path: 'contact',
        element: (
          <>
            <ScrollToTop />
            <Breadcrumbs
              title="Kontaktiere uns"
              section="Kontakt"
              desc="Zögern Sie nicht und kontaktieren Sie uns!"
            />
            <div className="single-page">
              <Contact />
            </div>
          </>
        ),
      },
      {
        path: 'impressum',
        element: (
          <>
            <ScrollToTop />
            <Breadcrumbs title="Impressum" section="Impressum" />
            <section className="single-page">
              <Impressum />
            </section>
          </>
        ),
      },
      {
        path: 'policy',
        element: (
          <>
            <ScrollToTop />
            <Breadcrumbs
              title="Datenschutzerklärung"
              section="Datenschutzerklärung"
            />
            <section className="single-page">
              <Policy />
            </section>
          </>
        ),
      },
    ],
  },
]);

AOS.init({
  duration: 1000,
  easing: 'ease-in-out',
  once: true,
  mirror: false,
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
