import React, { FC } from 'react';
import './Landing.css';
import Team from '../Team/Team';
import Hero from '../Hero/Hero';
import Services from '../Services/Services';
import Contact from '../Contact/Contact';
import AboutUs from '../AboutUs/AboutUs';
import GettingStarted from '../GettingStarted/GettingStarted';

interface LandingProps {}

const Landing: FC<LandingProps> = () => (
  <>
    <Hero />
    <Services />
    <main>
      <AboutUs />
      <GettingStarted />
      <Team showTitle={true} />
      <Contact showHeader={true} />
    </main>
  </>
);

export default Landing;
