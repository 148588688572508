import React, { FC } from 'react';
import './Career.css';

interface CareerProps {}

const Career: FC<CareerProps> = () => (
  <div className="container" data-aos="fade-up">
    <h1>Coming soon...</h1>
    <hr />
    <p className="additional-height">
      Falls du nicht warten möchtest, dann sende uns deine Initiativbewerbung an
      die{' '}
      <a href="mailto:bewerbung@otter-it-consulting.com">
        bewerbung@otter-it-consulting.com
      </a>
    </p>
  </div>
);

export default Career;
