import React, { FC } from 'react';
import './Hero.css';

interface HeroProps {}

const Hero: FC<HeroProps> = () => (
  <section id="hero" className="hero">
    <div className="container position-relative">
      <div className="row gy-5" data-aos="fade-in">
        <div className="col-lg-6 d-flex flex-column align-items-start justify-content-center text-left caption">
          <h2 data-aos="fade-left" data-aos-delay="100">
            IT Software-lösungen
          </h2>
          <h3 data-aos="zoom-out" data-aos-delay="400">
            Unser Geschäft ist es Ihrem Unternehmen beim Wachstum zu helfen
          </h3>
          <p data-aos="fade-left" data-aos-delay="6000">
            Top Web & Software Lösungen
          </p>
          <div className="d-flex" data-aos-delay="8000" data-aos="zoom-out">
            <a href="#about" className="btn-get-started">
              Get Started
            </a>
          </div>
        </div>
        <div className="col-lg-6 d-flex flex-column align-items-start justify-content-center">
          <img
            src={require('../../assets/images/hero.png')}
            className="img-fluid rounded-4 mb-4"
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
);

export default Hero;
