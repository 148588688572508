import React, { FC } from 'react';
import './AboutUs.css';

interface AboutUsProps {}

const AboutUs: FC<AboutUsProps> = () => (
  <section id="about" className="about">
    <div className="container" data-aos="fade-up">
      <div className="section-header">
        <h2>Über uns</h2>
        <p>Eine Übersicht unseres technologischen Know-Hows</p>
      </div>

      <div className="row gy-4">
        <div className="col-lg-6">
          <img
            src={require('../../assets/images/whyus.jpg')}
            className="img-fluid rounded-4 mb-4"
            alt=""
            loading="lazy"
          />
        </div>
        <div className="col-lg-6">
          <div className="content ps-0" data-aos="fade-right">
            <p>
              Ein Einblick in den Technologie-Stack unserer hochmotivierten
              Mitarbeiter
            </p>
            <div className="bar front expert" data-skill="Angular"></div>
            <div className="bar front expert" data-skill="Typescript"></div>
            <div className="bar front expert" data-skill="Javascript"></div>
            <div className="bar back expert" data-skill="React"></div>
            <div className="bar front expert" data-skill="HTML5"></div>
            <div className="bar front expert" data-skill="(S)CSS"></div>
            <div className="bar back expert" data-skill="Node.js"></div>
            <div className="bar back expert" data-skill="Java"></div>
            <div
              className="bar back expert"
              data-skill="Spring Boot"
            ></div>
            <div
              className="bar back expert"
              data-skill="Express.js"
            ></div>
            <div className="bar back expert" data-skill="Jenkins"></div>
            <div className="bar back expert" data-skill="Docker"></div>
            <div className="bar back expert" data-skill="und vieles mehr"></div>
            <p>
              Das Fachwissen wird stetig erweitert und vertieft. Sei es durch
              Schulungen, Konferenzen, Zertifizierungen oder andere Quellen.
            </p>
          </div>
        </div>
      </div>
    </div>
  </section>
);

export default AboutUs;
